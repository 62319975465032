<template>
    <div class="404">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center text-light header-container">
                <h1 class="display-4">Seite nicht gefunden</h1>
                <p class="lead w-75 mx-auto">
                    Tut uns Leid. Diese Seite existiert nicht!
                </p>
            </div>
        </div>
        <section>
            <b-container class="text-center my-5">
                <b-button to="/" variant="secondary" size="lg">
                    Zurück zur Startseite
                </b-button>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'team'
    }
</script>

<style lang="scss" scoped>
    .header-wrapper {
        background: linear-gradient(
                        to bottom right,
                        rgba(49, 49, 49, 0.7),
                        rgba(15, 0, 0, 0.6)
        ), url('../assets/img/lobby.png') center/cover no-repeat border-box;
        padding-top: 50px;
    }
</style>
